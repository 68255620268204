/**
 * to - promise resolver for async-await
 * help in catching error without have to define try-catch
 *
 * @template T
 * @param {Promise<T>} promise
 * @returns {Promise<[Error, T]>}
 */
const to = promise => promise.then(res => [null, res]).catch(err => [err]);

export default to;
