import axios from 'axios';

const { Tracker = {} } = global;

const isProd = process.env.NODE_ENV === 'production';
if (Tracker.config) {
  const config = {
    dev: !isProd,
  };

  if (!isProd) {
    config.baseUrl = 'http://t.local.host:9909';
  }

  Tracker.sendPixel = data => {
    const url = Tracker.pixel(data);
    return axios.get(url).catch(err => {
      // eslint-disable-next-line
      console.error(`ERROR when sending pixel tracker: ${err.message}`);
    });
  };

  Tracker.config(config);
}

export default Tracker;
