import { isObject } from '~shared-js/helpers/common-helpers';

// eslint-disable-next-line
export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const convertToString = (val, base = 10) => {
  const isValidVal = [null, undefined, false].indexOf(val) < 0;
  if (isValidVal && !isObject(val)) {
    const isValidBase = isNumeric(base) && base > 1 && base < 37;
    return isNumeric(val) && isValidBase ? val.toString(base) : val.toString();
  }
  return '';
};

export default {
  convertToString,
  isNumeric,
};
